import React from "react"
import { graphql } from "gatsby"
import * as styles from "../../styles/page/second.module.scss"
import specialCharacterReplace from "../../utils/special-character-replace"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"

// プロジェクトページコンポーネント
import { getCurrentProject, MainImage, ProjectTab, ProjectSideMenu } from "../../components/parts/project"

const ProjectArticlePage = ({ data }) => {
  const pageType = 'summary'
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const {
    slug,
    title,
    industry,
    category,
    summary: { budget },
    summary: { co2Effect },
    summary: { economicEffect },
    summary: { goal : summaryGoal },
    summary: { contents : summaryContents },
    basic : { goalTitleSuffix },
    basic : { effectTitleSuffix },
  } = frontmatter
  const { description } = getCurrentProject(slug)

  return (
    <Layout>
      <Meta 
        title={title}
        description={description}
        ogImage={`https://${process.env.HOST_NAME}/images${ slug.replace(pageType+'/', '') }og.png`}
        path={slug}
      />

      <BreadCrumb
        parentLink="/project/"
        parentTitle="プロジェクト情報"
        currentTitle={title}
      />

      <div className={styles.pageTitle}>
        <h1 className={styles.pageTitleHeading} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(title) }} />
      </div>

      <main className={styles.articleContents}>
        <article className={styles.articleContentsLeft}>
          <MainImage slug={slug} current={pageType} alt={title} />
          <ProjectTab current={pageType} slug={slug} />

          <div id="projectInformation" className={`${styles.tabPane} ${styles.activePane}`}>
            <div className={styles.articleInner}>
              <h2>プロジェクトサマリー</h2>

              <div className={`${styles.projectSummary} pb-1 ${industry}`}>
                <div className={`${styles.projectSummaryBackground} ${category}`}></div>
                <div className={styles.projectSummaryContent}>
                  <div className={styles.projectSummaryContentLeft}>
                    <h3>■予算額</h3>
                    <div className={styles.projectSummaryBudget} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(budget) }} />
                    <h3>■CO<sub>2</sub>の削減効果<span dangerouslySetInnerHTML={{ __html: specialCharacterReplace(goalTitleSuffix) }} /></h3>
                    <div className={styles.projectSummaryCo2Effect} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(co2Effect) }} />
                    <h3>■経済波及効果<span dangerouslySetInnerHTML={{ __html: specialCharacterReplace(effectTitleSuffix) }} /></h3>
                    <div className={styles.projectSummaryEconomicEffect} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(economicEffect) }} />
                  </div>
                  <div className={styles.projectSummaryContentRight}>
                    <div className={styles.projectSummaryGoal} dangerouslySetInnerHTML={{ __html: specialCharacterReplace(summaryGoal) }} />
                  </div>
                </div>
              </div>

              <div dangerouslySetInnerHTML={{ __html: specialCharacterReplace(summaryContents) }} />
              
            </div>
          </div>
        </article>
        
        <ProjectSideMenu slug={slug} type={`/${pageType}/`} current={pageType} />
      </main>
    </Layout>
  )
}
export default ProjectArticlePage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        slug
        title
        industry
        category
        basic {
          budgetValue
          budgetUnit
          goalTitleSuffix
          goal
          effectTitleSuffix
          effect
        }
        summary {
          budget
          co2Effect
          economicEffect
          goal
          contents
        }
      }
    }
  }
`